<nav class="navbar navbar-expand-lg py-1 bg-white">
  <div class="container flex-row-reverse flex-lg-row position-relative">
    <!-- If auth -- show on phone only -->
    <ng-container *ngIf="loading == 'true'; else userDataOnPhone">
      <p-skeleton styleClass="d-block d-lg-none" width="100px" height="40px"></p-skeleton>
    </ng-container>

    <ng-template #userDataOnPhone>
      <ng-container *ngIf="loading == 'userInfo'">
        <a class="nav-link auth-user pe-3 d-block d-lg-none border-0" role="button" routerLink="/wallets/all">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" >
            <path d="M0 15.832V17.5C0 18.8789 3.35938 20 7.5 20C11.6406 20 15 18.8789 15 17.5V15.832C13.3867 16.9688 10.4375 17.5 7.5 17.5C4.5625 17.5 1.61328 16.9688 0 15.832ZM12.5 5C16.6406 5 20 3.87891 20 2.5C20 1.12109 16.6406 0 12.5 0C8.35938 0 5 1.12109 5 2.5C5 3.87891 8.35938 5 12.5 5ZM0 11.7344V13.75C0 15.1289 3.35938 16.25 7.5 16.25C11.6406 16.25 15 15.1289 15 13.75V11.7344C13.3867 13.0625 10.4336 13.75 7.5 13.75C4.56641 13.75 1.61328 13.0625 0 11.7344ZM16.25 12.1641C18.4883 11.7305 20 10.9258 20 10V8.33203C19.0938 8.97266 17.7617 9.41016 16.25 9.67969V12.1641ZM7.5 6.25C3.35938 6.25 0 7.64844 0 9.375C0 11.1016 3.35938 12.5 7.5 12.5C11.6406 12.5 15 11.1016 15 9.375C15 7.64844 11.6406 6.25 7.5 6.25ZM16.0664 8.44922C18.4102 8.02734 20 7.19922 20 6.25V4.58203C18.6133 5.5625 16.2305 6.08984 13.7227 6.21484C14.875 6.77344 15.7227 7.52344 16.0664 8.44922Z" fill="#00ACA2"/>
          </svg>
          <span class="ms-2" *ngIf="userInfo">{{ userInfo.wallet.totalAmount | currency: userInfo.wallet.currency }}</span>
        </a>
      </ng-container>
      <ng-container *ngIf="loading == 'noUser'">
        <ul class="list-unstyled mb-0">
          <li class="nav-item d-block d-lg-none">
            <div class="dropdown user-options">
              <button class="login-button dropdown-toggle guest d-inline-block p-2 px-4 bg-white" type="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                <i class="pi pi-user me-2"></i>
                <span>Login / Register</span>
              </button>
              <ul class="dropdown-menu guest-menu shadow-sm">
                <li role="button" class="btn-main primary w-100" (click)="login()">Login</li>
                <li role="button" class="btn-main primary-outline w-100" routerLink="/auth/register">Register</li>
              </ul>
            </div>
          </li>
        </ul>
      </ng-container>
    </ng-template>

    <div class="mobile-left-side d-block me-auto lh-sm">
      <button class="navbar-toggler p-0 border-0 shadow-none me-3" type="button"
        aria-label="Toggle navigation" (click)="whenNavbarTogglerClicked()">
        <span class="navbar-toggler-icon my-2"></span>
      </button>
      <a class="navbar-brand m-0" routerLink="/">
        <img src="assets/images/fmtrade_logo-nobg_200.webp" alt="app logo" width="200px" height="72px" />
      </a>
    </div>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-lg-auto mb-2 mb-lg-0 gap-3 p-3 px-lg-0">
        @if (userInfo) {
        <li class="nav-item" *ngFor="let link of loggedInRoutes">
          <a class="nav-link" [routerLink]="link.path" routerLinkActive="active" [routerLinkActiveOptions]="link.routerLinkOptions">{{ link.name }}</a>
        </li>
        } @else {
        <li class="nav-item">
          <a class="nav-link" routerLink="/">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="scrollToServices()" role="button">Our Services</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/blog">Blog</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/all-faq">FAQs</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/contact-us" role="button">Contact Us</a>
        </li>
        }
      </ul>
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 d-none d-lg-flex">

        <ng-container *ngIf="loading == 'true'; else userData">
          <p-skeleton styleClass="me-3" width="100px" height="40px"></p-skeleton>
          <p-skeleton width="100px" height="40px"></p-skeleton>
        </ng-container>

        <ng-template #userData>
          <ng-container *ngIf="loading == 'userInfo'">
            <!-- Auth User Informaion -->
            <li class="nav-item" *ngIf="userInfo">
              <a class="nav-link auth-user px-3 d-inline-block border-0" role="button" [routerLink]="['/wallets']">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="me-1">
                  <path d="M0 15.832V17.5C0 18.8789 3.35938 20 7.5 20C11.6406 20 15 18.8789 15 17.5V15.832C13.3867 16.9688 10.4375 17.5 7.5 17.5C4.5625 17.5 1.61328 16.9688 0 15.832ZM12.5 5C16.6406 5 20 3.87891 20 2.5C20 1.12109 16.6406 0 12.5 0C8.35938 0 5 1.12109 5 2.5C5 3.87891 8.35938 5 12.5 5ZM0 11.7344V13.75C0 15.1289 3.35938 16.25 7.5 16.25C11.6406 16.25 15 15.1289 15 13.75V11.7344C13.3867 13.0625 10.4336 13.75 7.5 13.75C4.56641 13.75 1.61328 13.0625 0 11.7344ZM16.25 12.1641C18.4883 11.7305 20 10.9258 20 10V8.33203C19.0938 8.97266 17.7617 9.41016 16.25 9.67969V12.1641ZM7.5 6.25C3.35938 6.25 0 7.64844 0 9.375C0 11.1016 3.35938 12.5 7.5 12.5C11.6406 12.5 15 11.1016 15 9.375C15 7.64844 11.6406 6.25 7.5 6.25ZM16.0664 8.44922C18.4102 8.02734 20 7.19922 20 6.25V4.58203C18.6133 5.5625 16.2305 6.08984 13.7227 6.21484C14.875 6.77344 15.7227 7.52344 16.0664 8.44922Z" fill="#00ACA2"/>
                </svg>
                <span *ngIf="userInfo">
                  {{ userInfo.wallet.totalAmount | currency: userInfo.wallet.currency }}
                </span>
              </a>
            </li>
            <li class="nav-item" *ngIf="userInfo">
              <div class="dropdown user-options">
                <button class="btn btn-white border-0 dropdown-toggle ps-3 d-flex align-items-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <!-- if avatar null -->
                  <i *ngIf="!userInfo.avatar" class="bi bi-person-circle me-2 primary-color"></i>
                  <!-- if avatar exist -->
                  <img class="me-2 rounded-circle" *ngIf="userInfo.avatar" width="25px" height="25px" [src]="userInfo.avatar" alt="user avatar" />
                  <span *ngIf="userInfo" class="fw-bold">{{ userInfo.firstName }}</span>
                </button>
                <ul class="dropdown-menu loggedin-menu text-start p-0 overflow-hidden">
                  <li role="button" routerLink="/user/profile">
                    <i class="bi bi-person-circle me-2 primary-color"></i><span>Profile</span>
                  </li>
                  <li role="button" (click)="logout()">
                    <i class="bi bi-box-arrow-right me-2 primary-color"></i><span>Log out</span>
                  </li>
                </ul>
              </div>
            </li>
          </ng-container>

          <!-- Guest -->
          <ng-container *ngIf="loading == 'noUser'">
            <ul class="list-unstyled">
              <li class="nav-item">
                <div class="dropdown user-options">
                  <button class="login-button dropdown-toggle guest d-inline-block p-2 px-4 bg-white" type="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="pi pi-user me-2"></i>
                    <span>Login / Register</span>
                  </button>
                  <ul class="dropdown-menu guest-menu">
                    <li role="button" class="btn-main primary w-100 mb-3" (click)="login()">Login</li>
                    <li role="button" class="btn-main primary-outline w-100" routerLink="/auth/register">Register</li>
                  </ul>
                </div>
              </li>
            </ul>
          </ng-container>
        </ng-template>

      </ul>
      <!-- Auth -->
      <ul class="mobile-btn d-block d-lg-none px-0 w-100 px-3" *ngIf="userInfo">
        <button class="btn-main primary-outline w-100" (click)="logout()" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M1.77778 16C1.28889 16 0.870519 15.8261 0.522667 15.4782C0.174815 15.1304 0.000592593 14.7117 0 14.2222V1.77778C0 1.28889 0.174222 0.870519 0.522667 0.522667C0.871111 0.174815 1.28948 0.000592593 1.77778 0H8V1.77778H1.77778V14.2222H8V16H1.77778ZM11.5556 12.4444L10.3333 11.1556L12.6 8.88889H5.33333V7.11111H12.6L10.3333 4.84444L11.5556 3.55556L16 8L11.5556 12.4444Z" fill="#00ACA2"/>
          </svg>
          <span class="ms-2">Log out</span>
        </button>
      </ul>
    </div>
  </div>
</nav>
<p-toast></p-toast>

<!-- left menu - show on phones only max width 992px -->
<div class="navbar-mobile-menu position-fixed start-0 d-block d-lg-none overflow-hidden bg-white">
  <div class="container d-flex flex-column justify-content-between h-100">
    <ul class="list-unstyled d-flex flex-column align-items-start h-100">
      @if (userInfo) {
        <li class="nav-item" *ngFor="let link of loggedInPhoneRoutes">
          <a class="nav-link" [routerLink]="link.path">{{ link.name }}</a>
        </li>
      } @else {
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" routerLink="/">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="scrollToServices()" role="button">Our Services</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/blog">Blog</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/all-reviews">Reviews</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" routerLink="/all-faq">FAQs</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/contact-us" role="button">Contact Us</a>
        </li>
      }
    </ul>
    <!-- Auth -->
    <ul class="mobile-btn d-block d-lg-none px-0 w-100" *ngIf="userInfo">
      <button class="btn-main primary-outline w-100" (click)="logout()" role="button">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M1.77778 16C1.28889 16 0.870519 15.8261 0.522667 15.4782C0.174815 15.1304 0.000592593 14.7117 0 14.2222V1.77778C0 1.28889 0.174222 0.870519 0.522667 0.522667C0.871111 0.174815 1.28948 0.000592593 1.77778 0H8V1.77778H1.77778V14.2222H8V16H1.77778ZM11.5556 12.4444L10.3333 11.1556L12.6 8.88889H5.33333V7.11111H12.6L10.3333 4.84444L11.5556 3.55556L16 8L11.5556 12.4444Z" fill="#00ACA2"/>
        </svg>
        <span class="ms-2">Log out</span>
      </button>
    </ul>
  </div>
</div>

