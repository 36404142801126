<router-outlet></router-outlet>

<p-toast></p-toast>
<!-- custom toaster for refresh token -->
<div class="custom-toaster">
  <p-toast position="bottom-left" key="sessionAlert" (onClose)="onReject()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
      <div class="d-flex flex-row align-items-center w-100 parent-toast">
        <div class="mx-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path
              d="M17.2954 1.83811C17.0937 1.32927 16.7744 0.890439 16.3707 0.567201C15.9671 0.243964 15.4938 0.0481144 15 0C14.5089 0.0510024 14.0387 0.248186 13.6379 0.571256C13.2371 0.894327 12.9201 1.33161 12.7196 1.83811L0.53739 25.5481C-0.722837 27.9933 0.31235 30 2.8178 30H27.1822C29.6877 30 30.7228 27.9933 29.4626 25.5481L17.2954 1.83811ZM16.5003 24.941H13.4997V21.5683H16.5003V24.941ZM16.5003 18.1956H13.4997V8.07757H16.5003V18.1956Z"
              fill="#D5A00F" />
          </svg>
        </div>
        <div class="message my-0 px-3">
          <p class="mb-0">This page is out of date.</p>
          <p class="mb-0">please refresh your browser.</p>
          <!-- {{ message.summary }} -->
        </div>
        <button class="btn-refresh" (click)="refreshUserTokenInApp();">
          <i class="pi pi-spin pi-spinner" *ngIf="isRefreshingToken"></i>
          <span *ngIf="!isRefreshingToken">Refresh</span>
        </button>
      </div>
    </ng-template>
  </p-toast>
</div>
