import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AuthService } from '../../../modules/authentication/services/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Subscription } from 'rxjs';
// prime ng
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ToastModule,
    SkeletonModule
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  providers: [MessageService]
})
export class NavbarComponent implements OnInit, OnDestroy {
  // public userInfo: IUser | null = null;
  public isloggedIn: boolean = false;
  public isGettingsUserInfo: boolean = false;
  public loading: string = 'true';

  loggedInRoutes = [
    {
      path: '/',
      name: 'Home',
      routerLinkOptions: { exact: true },
    },
    {
      path: '/user/dashboard',
      name: 'Dashboard',
      routerLinkOptions: { exact: true },
    },
    {
      path: '/exchange',
      name: 'Exchanges',
      routerLinkOptions: { exact: true },
    },
    {
      path: '/tickets',
      name: 'Ticket',
      routerLinkOptions: { exact: true },
    },
    {
      path: '/wallets',
      name: 'Wallets',
      routerLinkOptions: { exact: false },
    },
  ];

  loggedInPhoneRoutes = [
    {
      path: '/',
      name: 'Home'
    },
    {
      path: '/user/profile',
      name: 'Profile'
    },
    {
      path: '/user/dashboard',
      name: 'Dashboard'
    },
    {
      path: '/exchange',
      name: 'Exchanges'
    },
    {
      path: '/tickets',
      name: 'Ticket'
    },
    {
      path: '/wallets',
      name: 'Wallets'
    },
  ];

  private _Router = inject(Router);
  private _AuthService = inject(AuthService);
  private _Subscription = new Subscription();
  private _MessageService = inject(MessageService)
  private _CookiesService = inject(SsrCookieService);

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {
    this._AuthService.isLoggedIn.subscribe(res => this.isloggedIn = res)
    this._AuthService.isGettingUserData.subscribe(res => this.isGettingsUserInfo = res);
    this.checkUserInfo();
  }

  checkUserInfo() {
    if (isPlatformBrowser(this.platformId)) {
      const userInfo = this._AuthService.getLoggedInUserInfo;
      if (userInfo && this._CookiesService.get('tokenUser')) {
        this.loading = 'userInfo';
      } else {
        this.loading = 'noUser';
      }
    }
  }

  get userInfo() {
    // Only access cookies in the browser
    if (isPlatformBrowser(this.platformId)) {
      const userInfo = this._AuthService.getLoggedInUserInfo;
      this.checkUserInfo(); // check again the state
      return userInfo ? userInfo : null;
    }
    return null;
  }

  logout() {
    this._MessageService.add({ severity: 'success', summary: 'Success', detail: "You Have Logged out"});
    this._AuthService.logoutUser();
    this._AuthService.logoutState.next(true);
  }

  login() {
    if (this._CookiesService.get('tokenUser')) {
      this._CookiesService.deleteAll('/')
      this._CookiesService.deleteAll()
    }
    this._Router.navigate(['/auth/login'])
  }

  scrollToServices() {
    this._Router.navigate(['']);
    setTimeout(function () {
      document.getElementById('our-services')?.scrollIntoView({ behavior: 'smooth' });
    }, 500)
  }

  whenNavbarTogglerClicked() {
    if (!document.querySelector('.navbar-mobile-menu')?.classList.contains('active')) {
      document.querySelector('.navbar-mobile-menu')?.classList.add('active');
    } else {
      document.querySelector('.navbar-mobile-menu')?.classList.remove('active');
    }
  }

  ngOnDestroy(): void {
    this._Subscription.unsubscribe();
  }

}
