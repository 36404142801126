<section class="full-content-layout">
  <div class="container h-100">
    <p-toast></p-toast>
    <p class="auth-nav pt-5 text-uppercase fs-4 fw-bold">
      <a routerLink="/" class="logo">
        <img src="assets/images/fmtrade_logo-nobg_200.webp" alt="app logo" width="200px" height="72px" />
      </a>
    </p>

    <div class="row align-items-center justify-content-center parent">
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>
