import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../../modules/authentication/services/auth.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Router } from '@angular/router';
import { catchError, switchMap, throwError } from 'rxjs';

export const apiInterceptor: HttpInterceptorFn = (req, next) => {

  const _Router = inject(Router)
  const _AuthService = inject(AuthService);
  const _CookieService = inject(SsrCookieService)
  const authToken = _AuthService.userToken;

  // Clone the request and add the authorization header
  if (authToken) {
    // upload image request needs that there is no Authorization sent
    if (req.headers.get("Content-Type")) {
      return next(req);
    }

    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`
      }
    });
  }

  // Pass the cloned request with the updated header to the next handler
  return next(req).pipe(
    catchError((error) => {

      // if Unauthorized or 403 forbidden
      // this causes a problem i think makes the token and refresh token duplicate
      if ([401, 403].includes(error.status)) {
        //  401 has three cases
        /*
          1- token is not there
          2- token exist but expired
          3- no token but there is hashId -- when user login but need to verify email Or Confirm 2FA Code
          4- everyting is fine but user has invalid email so the res comes with 401 cannot send confirmation email to him
        */


        if (error.error.message == "You need to verify your email first") {
          _AuthService.logoutUser(); // log out - reset - and go to home
        }

        if (_CookieService.check('isEmailVerified') && _CookieService.get('isEmailVerified') == "false") {
          _AuthService.logoutUser();
        }

        // if there is isEmailVerified and true freshTheToken
        if (_CookieService.check('isEmailVerified') && _CookieService.get('isEmailVerified') == "true") {
          if (_CookieService.check('tokenUser')) {
            return _AuthService.freshToken().pipe(
              switchMap(newToken => {
                // here there is duplicate happens so we need to delete all first
                _CookieService.delete('tokenUser', '/')
                _CookieService.delete('tokenUserRefresh', '/')
                // then fill the new data from the response
                _CookieService.set('tokenUser', newToken.accessToken, undefined, '/');
                _CookieService.set('tokenUserRefresh', newToken.refreshToken, undefined, '/');

                // alert to tell that client session is refreshed due to security reason
                console.log("%c token refreshed now and request will continue", 'background: #222; color: #bada55');

                // after getting the new Token using tokenUserRefresh  - you need to get the userInfo again by it
                // if email is verified
                if (_AuthService.getLoggedInUserInfo.isEmailVerified) {
                  // This What Gives Unauthorized
                  _AuthService.validateUser()
                }

                // clonse the request then run it again with the new token
                const clonedRequest = req.clone({
                  setHeaders: {
                    Authorization: `Bearer ${newToken.accessToken}`
                  }
                });

                return next(clonedRequest);
              }),
              catchError(refreshError => {
                console.log("%c token refreshing function has error you will be logged out now", 'background: #222; color: #bada55');
                _AuthService.logoutUser();
                return throwError(() => refreshError);
              })
            );
          }
        }

        // if there is No isEmailVerified freshTheToken
        if (!_CookieService.check('isEmailVerified')) {
          if (_CookieService.check('tokenUser')) {
            _AuthService.freshTheToken();
          }
        }

      }

      if ([503].includes(error.status)) {
        _Router.navigate(['/maintainance']);
      }

      // if token refresh and token are gone - expired
      if ([410].includes(error.status)) {
        _AuthService.logoutUser();
        _Router.navigateByUrl('/auth/login');
      }

      return throwError(() => error)
    })
  );


}


