import { ChatSocketService } from './modules/tickets/services/chat-socket.service';
import { Component, Inject, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService } from './modules/authentication/services/auth.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { TicketsService } from './modules/tickets/services/tickets.service';
import { ExchangeDirectionService } from './modules/exchange-direction/services/exchange-direction.service';
import { UploadImageService } from './modules/user/services/upload-image.service';
import { CountdownTime } from './modules/authentication/interfaces/confirm-code';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ToastModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [AuthService, TicketsService, ExchangeDirectionService, ChatSocketService, UploadImageService, MessageService]
})
export class AppComponent implements OnDestroy {

  public isLoading!: boolean; // if app is loading

  // timer count down
  public timerIsOn: boolean = true;
  public isRefreshingToken: boolean = false;
  public visible: boolean = false; // for toast
  public totalSeconds:number = 900;
  public countdown: CountdownTime = { minutes: 0, seconds: 900 };

  private isBrowser: boolean;
  private _Router = inject(Router);
  private _AuthService = inject(AuthService)
  private _MessageService = inject(MessageService);
  private _CookiesService = inject(SsrCookieService);
  private _Subscription = new Subscription();

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      this._AuthService.validateUser(); // get userinfo by token
      this.validateUser();
      // refresh user info using new token
      // if (this._CookiesService.check('tokenUser')) {
      //   this._AuthService.validateUser(); // get userinfo by token
      // }
    } else {
      // Add any server-specific logic here if needed
    }
  }

  validateUser() {
    // refresh user Token
    setInterval(() => {
      this.showRefreshTokenMessage(); // show toast message to tell token expired
    }, 900000); // 900000 default token expiary time
  }

  refreshUserTokenInApp() {
    // refresh tokenUser After 15 minutes  and also refresh userinfo
    this.isRefreshingToken = true;

    this._Subscription.add(
      this._AuthService.freshToken().subscribe({
        next: (res) => {
          // here there is duplicate happens so we need to delete all first
          this._CookiesService.delete('tokenUser', '/')
          this._CookiesService.delete('tokenUserRefresh', '/')
          // then fill the new data from the response
          this._CookiesService.set('tokenUser', res.accessToken, undefined, '/');
          this._CookiesService.set('tokenUserRefresh', res.refreshToken, undefined, '/');
          // after getting the new Token using tokenUserRefresh  - you need to get the userInfo again by it
          console.log("%c token refreshed now", 'background: #222; color: #bada55');
          // redirect to home then hide the loading screen
          // alert to tell that client session is refreshed due to security reason

          // if email is verified
          if (this._AuthService.getLoggedInUserInfo.isEmailVerified) {
            // This What Gives Unauthorized
            this._AuthService.validateUser();
          }
          this.isRefreshingToken = false;
          this.onReject();
        },
        error: (err) => {
          console.log('err: ', err);
          this.isRefreshingToken = false;
          this.onReject();
          this._Router.navigate(['/auth/login']);
        }
      })
    );

  }


  ngOnDestroy(): void {
    this._Subscription.unsubscribe();
  }

  // start toast methods

  showRefreshTokenMessage() {
    if (!this.visible) {
      this._MessageService.add({ key: 'sessionAlert', sticky: true, severity: 'success', summary: 'Can you send me the report?' });
      this.visible = true;
    }
  }

  onConfirm() {
    this._MessageService.clear('sessionAlert');
    this.visible = false;
  }

  onReject() {
    this._MessageService.clear('sessionAlert');
    this.visible = false;
  }
  // end toast methods
}
